import "./FullTestStep.css";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import httpService from "../../../services/http.service";
import { getErrorMessage } from "../../Helper/Helper";
import { logPageTitleForGA } from "../../Helper/Helper";
import TestStepRevamp from "./TestStepRevamp";
import NoAccess from "../../UI/NoAccess/NoAccess";
import { toast } from "react-toastify";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";

const FullTestStep = () => {
  const [searchParams] = useSearchParams();
  const [mainData, setMainData] = useState();
  const [filterData, setFilterData] = useState(null);
  const [pid, setPid] = useState(null);
  const [noAccess, setNoAccess] = useState(false);

  useEffect(() => {
    const filterData = searchParams.get("filters")
      ? setFilterData(searchParams.get("filters"))
      : null;
    const apiPid = searchParams.get("p_id");
    const pid = searchParams.get("p_id")
      ? setPid(searchParams.get("p_id"))
      : null;

    let title = "Jewel | Test Step Report : " + searchParams.get("tc_run_id");
    document.title = title;
    logPageTitleForGA(title);
    httpService
      .getTestStepReportData(searchParams.get("tc_run_id"), apiPid)
      .then((data) => {
        getErrorMessage(data?.data);
        setMainData(data?.data);
      })
      .catch((err) => {
        if (
          err["response"]["data"]["operation"].toLowerCase() === "info" &&
          err["response"]["data"]["suboperation"]
        ) {
          setNoAccess(true);
        } else {
          handleToastErrorCatch(err);
        }
      });
  }, []);
  return (
    <>
      <div className="container-fluid pt-3 pb-3">
        {mainData ? (
          <>
            <TestStepRevamp
              key={searchParams.get("tc_run_id")}
              data={mainData}
              filterMain={filterData}
              shared={true}
              p_id={pid}
            />
          </>
        ) : noAccess ? (
          <NoAccess />
        ) : (
          <TestStepRevamp
            data={null}
            filterMain={filterData}
            shared={true}
            p_id={pid}
          />
        )}
      </div>
    </>
  );
};

export default FullTestStep;
