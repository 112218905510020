import React from "react";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { formatDecimal, IncrementIndicator } from "../../../Helper/Helper";
import { Skeleton } from "primereact/skeleton";
import { ProgressBar } from "primereact/progressbar";
const ProgressBarCard = ({
    title,
    stabilityData,
    stabilityDataLoading,
    progressBarValue,
    isIncrementPercent,
    duration,
    increment,
    infoText
}) => {
    const valueTemplate = () => {
        return <React.Fragment></React.Fragment>;
    };
    const CustomTooltip = ({ text, name }) => {
        const [showInfo, setShowInfo] = useState(false);
        return (
            <>
                <Dialog
                    blockScroll={true}
                    draggable={false}
                    header={name}
                    visible={showInfo}
                    onHide={() => {
                        setShowInfo(false);
                    }}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "30vw" }}
                >
                    <p>{text}</p>
                </Dialog>
                <i className="pi pi-info-circle fs-5 pointer" onClick={() => setShowInfo(true)}></i>
            </>
        );
    };
    return (
        <>

            {!stabilityDataLoading ? (
                <div className="stabilityCard col-3 col-md-4 mt-2">
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center gap-2">
                            <p className="m-0 ps-3 fs-5">
                                {title}
                            </p>
                            <CustomTooltip text={infoText} key={title} name={title} />
                        </div>
                        {stabilityData?
                        <>
                        <div className="d-flex ms-3 mt-2">
                            <p className="me-2 fs-5">{formatDecimal(stabilityData)}{isIncrementPercent && "%"}</p>
                            {(increment!==undefined&&increment!==null&&increment!==0&&increment!=="0")
                            &&(
                            <>
                           <IncrementIndicator value={formatDecimal(increment)} />
                            <p style={{ color: increment < 0 ? "red" : "green" }}>
                             {(formatDecimal(increment)!==0&&isIncrementPercent)&& 
                             <p style={{marginTop:"0.2rem",height:"1px",width:"1px"}}>%</p>
                             }
                             </p>
                             </>
                            )}
                        </div>
                        <p className="mt-auto ms-3 durationText">Based on past {duration} days</p>
                        <ProgressBar
                            className="mt-1 mx-2"
                            style={{ height: "8px" }}
                            color="#ff7e7c"
                            value={progressBarValue}
                            showValue="false"
                            displayValueTemplate={valueTemplate}
                        ></ProgressBar>
                        </>
                        :(<div className="fadeText">No Data Found</div>)}
                    </div>
                </div>
             
            ) : (
                <Skeleton className="stabilityCard"></Skeleton>
            )}
        </>
    );
};
export default ProgressBarCard;