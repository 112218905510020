import axios from "axios";
import authHeader from "./auth-header";
import * as Environment from "../services/Environment";
import SockJS from "sockjs-client";
import auth from "../reducers/auth";
import {
  API_URLS,
  MICRO_API_URL_NOTIFICATION,
  MICRO_API_URL_REPORT,
  MICRO_API_URL_S3,
  MICRO_API_URL_TESTEXECUTION,
  MICRO_API_URL_TESTPAD,
  MICRO_API_URL_FRAMEWORK,
} from "../config/API_URLS";
import { MICRO_API_URL_USER } from "../config/API_URLS";
import { MICRO_API_URL_DASHBOARD } from "../config/API_URLS";
import { MICRO_API_URL_PROJECT } from "../config/API_URLS";

let API_URL;
let pypBaseUrl;
let jarBaseUrl;

if (Environment.checkEnv() === "prod") {
  // API_URL = "https://apis.gemecosystem.com/";
  pypBaseUrl = "https://gempyp.gemecosystem.com";
  jarBaseUrl = "https://gemjar.gemecosystem.com";
} else {
  // API_URL = "https://apis-beta.gemecosystem.com/";
  pypBaseUrl = "https://gempyp-beta.gemecosystem.com";
  jarBaseUrl = "https://gemjar-beta.gemecosystem.com";
  // API_URL = "http://ec2-13-127-221-50.ap-south-1.compute.amazonaws.com:8080/";
}

class HttpService {
  //gemeco url : validate/username?username=
  validateUserName(user) {
    return axios
      .get(
        MICRO_API_URL_USER.baseURL +
          "userManagement/v1/validate/username?username=" +
          user,
        {
          // .get(API_URLS.baseURL + "validate/username?username=" + user, {
          headers: authHeader(),
        }
      )

      .then((data) => {
        return Promise.resolve(data.data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  getBridgeToken() {
    return (
      axios
        .get(MICRO_API_URL_USER.baseURL + "v1/bridge/token", {
          headers: authHeader(),
        })
        // .get(API_URLS.baseURL + "bridge/token", { headers: authHeader() })
        .then((data) => {
          return Promise.resolve(data.data);
        })
        .catch((err) => Promise.reject(err))
    );
  }
  getLastToken(count) {
    return axios
      .get(MICRO_API_URL_USER.baseURL + "v1/bridge/token?count=" + count, {
        // .get(API_URLS.baseURL + "bridge/token?count=" + count, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getLastFiveRuns(s_run_id, category) {
    return axios
      .get(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "v2/suiteexe/lastFive?s_run_id=" +
          s_run_id +
          "&category=" +
          category,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getExtentReportData(s_run_id, pageNo, pageSize, pid) {
    return axios
      .get(
        MICRO_API_URL_REPORT.baseURL + "v3/rule/action?s_run_id=" + s_run_id,
        {
          headers: authHeader(),
          params: {
            pageNo: pageNo,
            pageSize: pageSize,
            pid: pid,
          },
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  getTestStepReportData(tc_run_id, pid) {
    return axios
      .get(
        MICRO_API_URL_REPORT.baseURL + "v3/rule/action?tc_run_id=" + tc_run_id,
        {
          headers: authHeader(),
          params: {
            pid: pid,
          },
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getSuiteTimeline(payload, checked, tokenUser, pageNo, pageSize) {
    let category = checked ? "criteria" : "all";

    return axios
      .post(
        MICRO_API_URL_REPORT.baseURL +
          "v1/timeline?category=" +
          category +
          "&search=" +
          tokenUser,
        // .post(API_URLS.baseURL + "timeline?category=" + category + "&search=" + tokenUser,
        payload,
        {
          headers: authHeader(),
          params: {
            pageNo: pageNo,
            pageSize: pageSize,
          },
        }
      )
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }

  getSuiteTickets(id) {
    return axios
      .get(MICRO_API_URL_REPORT.baseURL + "v1/tickets?s_run_id=" + id, {
        // .get(API_URLS.baseURL + "tickets?s_run_id=" + id, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  updateToken() {
    return axios
      .post(MICRO_API_URL_USER.baseURL + "v1/bridge/token/change", null, {
        // .post(API_URLS.baseURL + "bridge/token/change", null, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getReportDetails() {
    return (
      axios
        .get(MICRO_API_URL_DASHBOARD.baseURL + "v1/report-names", {
          headers: authHeader(),
        })
        // .get(API_URLS.baseURL + "report-names", { headers: authHeader() })
        .then((data) => {
          return Promise.resolve(data.data);
        })
        .catch((err) => Promise.reject(err))
    );
  }
  getProjectRole() {
    return (
      axios
        .get(MICRO_API_URL_PROJECT.baseURL + "v1/project/role", {
          headers: authHeader(),
        })
        // .get(API_URLS.baseURL + "project/role", { headers: authHeader() })
        .then((data) => {
          return Promise.resolve(data.data);
        })
        .catch((err) => Promise.reject(err))
    );
  }
  getProjectData(pids) {
    return axios
      .get(
        MICRO_API_URL_PROJECT.baseURL + "v1/projects?pid=" + pids.toString(),
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  removeProjectUser(payload) {
    return axios
      .post(
        MICRO_API_URL_PROJECT.baseURL + "v2/project/role/remove ",
        payload,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  getPendingProjectRemovalRequests() {
    return axios
      .get(
        MICRO_API_URL_PROJECT.baseURL +
          "v2/project/role/users/removal/requests",
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data?.data);
      })
      .catch((err) => Promise.reject(err));
  }
  postProjectRemovalRequest(payload, action) {
    return axios
      .post(
        MICRO_API_URL_PROJECT.baseURL +
          "v2/project/role/users/removal/requests",
        payload,
        {
          headers: authHeader(),
          params: {
            action: action,
          },
        }
      )
      .then((data) => {
        return Promise.resolve(data?.data);
      })
      .catch((err) => Promise.reject(err));
  }
  getRuleApiData(payload) {
    return (
      axios
        .post(MICRO_API_URL_REPORT.baseURL + "v1/rule", payload, {
          headers: authHeader(),
        })
        // .post(API_URLS.baseURL + "rule", payload, { headers: authHeader() })
        .then((data) => {
          return Promise.resolve(data.data ? data.data : []);
        })
        .catch((err) => Promise.reject(err))
    );
  }

  getAvailableTestToolSuites() {
    return axios
      .get(MICRO_API_URL_TESTEXECUTION.baseURL + "suite", {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getAdminProjects() {
    return (
      axios
        // .get(API_URLS.baseURL + "v2/project", {
        .get(MICRO_API_URL_PROJECT.baseURL + "v2/project", {
          headers: authHeader(),
        })
        .then((data) => {
          return Promise.resolve(data.data);
        })
        .catch((err) => Promise.reject(err))
    );
  }

  getRequestAccProjects() {
    return axios
      .get(MICRO_API_URL_PROJECT.baseURL + "v2/getAllProjects", {
        // .get(API_URLS.baseURL + "v2/getAllProjects", {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  validateProjectName(project) {
    return axios
      .get(
        MICRO_API_URL_PROJECT.baseURL + "v2/project/validate?name=" + project,
        {
          // .get(API_URLS.baseURL + "v2/project/validate?name=" + project, {
          headers: authHeader(),
        }
      )

      .then((data) => {
        return Promise.resolve(data.data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  validateCompanyName(company) {
    return axios
      .get(
        MICRO_API_URL_USER.baseURL +
          "companyManagement/v2/verify/companyName?name=" +
          company,
        {
          // .get(API_URLS.baseURL + "v2/verify/companyName?name=" + company, {
          headers: authHeader(),
        }
      )

      .then((data) => {
        return Promise.resolve(data.data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getProjectRoleRequests() {
    return axios
      .get(MICRO_API_URL_PROJECT.baseURL + "v2/project/role/users/request", {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getAccess(payload) {
    return axios
      .post(
        MICRO_API_URL_PROJECT.baseURL + "v2/project/role/request",
        payload,
        {
          // .post(API_URLS.baseURL + "v2/project/role/request", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }

  companyDetails() {
    return axios
      .get(
        MICRO_API_URL_USER.baseURL + "companyManagement/v2/company/details",
        {
          // .get(API_URLS.baseURL + "v2/company/details", {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  companyDetailsSuperAdmin(company) {
    return axios
      .get(
        MICRO_API_URL_USER.baseURL +
          "companyManagement/v2/company/details?name=" +
          company,
        {
          // .get(API_URLS.baseURL + "v2/company/details?name=" + company, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  //gemeco url : v2/verified/users?name=
  companyUsersSuperAdmin(companyName) {
    return axios
      .get(
        MICRO_API_URL_USER.baseURL +
          "userManagement/v1/verified/users?realCompanyName=" +
          companyName,
        {
          // .get(API_URLS.baseURL + "v2/verified/users?name=" + companyName, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  //gemeco url : v2/company/users
  companyUsers() {
    return axios
      .get(MICRO_API_URL_USER.baseURL + "userManagement/v1/company/users", {
        // .get(API_URLS.baseURL + "v2/company/users", {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  //gemeco url : v2/company/deleted/users
  blockedUsers() {
    return axios
      .get(
        MICRO_API_URL_USER.baseURL + "userManagement/v1/company/deleted/users",
        {
          // .get(API_URLS.baseURL + "v2/company/deleted/users", {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  //gemeco url : v2/company/deleted/users?name=
  blockedUsersSuperAdmin(name) {
    return axios
      .get(
        MICRO_API_URL_USER.baseURL +
          "userManagement/v1/company/deleted/users?companyName=" +
          name,
        {
          // .get(API_URLS.baseURL + "v2/company/deleted/users?name=" + name, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  //gemeco url : v2/unblock/users
  unblockUsers(payload) {
    return axios
      .post(
        MICRO_API_URL_USER.baseURL + "userManagement/v1/unblock/users",
        payload,
        {
          // .post(API_URLS.baseURL + "v2/unblock/users", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => {
        Promise.reject(err);
      });
  }

  //gemeco url : v2/company/admins?name=
  companyAdmins(companyName) {
    return axios
      .get(
        MICRO_API_URL_USER.baseURL +
          "userManagement/v1/company/admins?realCompanyName=" +
          companyName,
        {
          // .get(API_URLS.baseURL + "v2/company/admins?name=" + companyName, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  addCompanyDomain(payload) {
    return axios
      .post(
        MICRO_API_URL_USER.baseURL + "companyManagement/v2/update/domains",
        payload,
        {
          // .post(API_URLS.baseURL + "v2/update/domains", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => {
        Promise.reject(err);
      });
  }

  addCompanyDomainSuperAdmin(company, payload) {
    return axios
      .post(
        MICRO_API_URL_USER.baseURL +
          "companyManagement/v2/update/domains?name=" +
          company,
        payload,
        {
          // .post(API_URLS.baseURL + "v2/update/domains?name=" + company, payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => {
        Promise.reject(err);
      });
  }
  getAllCompany() {
    return axios
      .get(MICRO_API_URL_USER.baseURL + "companyManagement/v2/getAllCompany", {
        // .get(API_URLS.baseURL + "v2/getAllCompany", {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  //gemeco url : v2/add/unverified/user?username=
  addNewCompanyUser(username) {
    return axios
      .post(
        MICRO_API_URL_USER.baseURL +
          "userManagement/v1/add/unverified/user?username=" +
          username,
        // API_URLS.baseURL + "v2/add/unverified/user?username=" + username,
        null,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  //gemeco url : v2/delete/user
  blockExisitingUser(payload) {
    return axios
      .post(
        MICRO_API_URL_USER.baseURL + "userManagement/v1/delete/user",
        payload,
        {
          // .post(API_URLS.baseURL + "v2/delete/user", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  createNewProject(payload) {
    return (
      axios
        // .post(API_URLS.baseURL + "v2/project", payload, { headers: authHeader() })
        .post(MICRO_API_URL_PROJECT.baseURL + "v2/project", payload, {
          headers: authHeader(),
        })
        .then((data) => {
          return Promise.resolve(data.data);
        })
        .catch((err) => Promise.reject(err))
    );
  }
  sendMail(payload) {
    return (
      axios
        // .post(API_URLS.baseURL + "v1/email/add", payload, {
        .post(MICRO_API_URL_TESTEXECUTION.baseURL + "v1/email/add", payload, {
          headers: authHeader(),
        })
        .then((data) => {
          return Promise.resolve(data.data ? data.data : []);
        })
        .catch((err) => Promise.reject(err))
    );
  }
  unlinkMail(s_id) {
    return axios
      .delete(
        MICRO_API_URL_TESTEXECUTION.baseURL + "v1/email/delete?s_id=" + s_id,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }
  addUserRequest(payload) {
    return axios
      .post(
        MICRO_API_URL_PROJECT.baseURL + "v2/project/role/add/user",
        payload,
        {
          // .post(API_URLS.baseURL + "v2/project/role/add/user", payload, {
          headers: authHeader(),
          params: {
            app: "jewel",
          },
        }
      )
      .then((data) => {
        return Promise.resolve(data.data ? data.data : {});
      })
      .catch((err) => Promise.reject(err));
  }

  getCompany(email) {
    return axios
      .get(
        MICRO_API_URL_USER.baseURL +
          "companyManagement/v1/company/real?email=" +
          email,
        {
          // .get(API_URLS.baseURL + "company/real?email=" + email, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  acceptRoleRequest(payload) {
    return axios
      .post(MICRO_API_URL_PROJECT.baseURL + "v2/project/role/accept", payload, {
        // .post(API_URLS.baseURL + "v2/project/role/accept", payload, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : {});
      })
      .catch((err) => Promise.reject(err));
  }

  declineRoleRequest(payload) {
    return axios
      .post(
        MICRO_API_URL_PROJECT.baseURL + "v2/project/role/request/decline",
        payload,
        {
          // .post(API_URLS.baseURL + "v2/project/role/request/decline", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data ? data.data : {});
      })
      .catch((err) => Promise.reject(err));
  }

  getPendingRolesRequest() {
    return axios
      .get(MICRO_API_URL_PROJECT.baseURL + "v2/project/role/request/pending", {
        // .get(API_URLS.baseURL + "project/role/request/pending", {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  postPendingProjectRequest(params) {
    return axios
      .post(
        MICRO_API_URL_PROJECT.baseURL + "v1/project/creation/requests",
        null,
        {
          headers: authHeader(),
          params: params,
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  getPendingProjectRequests() {
    return axios
      .get(MICRO_API_URL_PROJECT.baseURL + "v1/project/creation/requests", {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  editProjectDetails(id) {
    return axios
      .get(MICRO_API_URL_PROJECT.baseURL + "v2/project/jsonformat?pid=" + id, {
        // .get(API_URLS.baseURL + "v2/project/jsonformat?pid=" + id, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  deleteProject(id) {
    return axios
      .delete(MICRO_API_URL_PROJECT.baseURL + "v2/project?pid=" + id, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  removeProjectUser(payload) {
    return axios
      .post(
        MICRO_API_URL_PROJECT.baseURL + "v2/project/role/remove ",
        payload,
        {
          // .post(API_URLS.baseURL + "v2/project/role/remove ", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getProjectUsers(id) {
    return axios
      .get(MICRO_API_URL_PROJECT.baseURL + "v2/project/role/users?pid=" + id, {
        // .get(API_URLS.baseURL + "v2/project/role/users?pid=" + id, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  //gemeco url : v2/getSameCompanyUser?pid=
  getSameCompanyUsers(id) {
    return axios
      .get(
        MICRO_API_URL_USER.baseURL +
          "userManagement/v1/getSameCompanyUser?pid=" +
          id,
        {
          // .get(API_URLS.baseURL + "v2/getSameCompanyUser?pid=" + id, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => {
        Promise.reject(err);
      });
  }

  //gemeco url : v2/getSameCompanyUser
  getSameCompanyUsersWithoutPid(company) {
    return axios
      .get(
        MICRO_API_URL_USER.baseURL +
          "userManagement/v1/getSameCompanyUser?company=" +
          company,
        {
          // .get(API_URLS.baseURL + "v2/getSameCompanyUser", {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => {
        Promise.reject(err);
      });
  }

  //gemeco url : v2/remove/admin
  removeAdmin(payload) {
    return axios
      .post(
        MICRO_API_URL_USER.baseURL + "userManagement/v1/remove/admin",
        payload,
        {
          // .post(API_URLS.baseURL + "v2/remove/admin", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  //gemeco url : v2/add/admin
  addAdmin(payload) {
    return axios
      .post(
        MICRO_API_URL_USER.baseURL + "userManagement/v1/add/admin",
        payload,
        {
          // .post(API_URLS.baseURL + "v2/add/admin", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  verifyNewCompany(companyName) {
    return axios
      .post(
        MICRO_API_URL_USER.baseURL +
          "companyManagement/v2/add/verified/company?name=" +
          companyName,
        // API_URLS.baseURL + "v2/add/verified/company?name=" + companyName,
        null,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  blockCompany(companyName) {
    return axios
      .post(
        MICRO_API_URL_USER.baseURL +
          "companyManagement/v2/remove/verified/company?name=" +
          companyName,
        // API_URLS.baseURL + "v2/remove/verified/company?name=" + companyName,
        null,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  createNewCompany(payload) {
    return axios
      .post(
        MICRO_API_URL_USER.baseURL +
          "companyManagement/v2/create/verified/company",
        payload,
        {
          // .post(API_URLS.baseURL + "v2/create/verified/company", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  changeExistingProjectRole(payload) {
    return axios
      .post(MICRO_API_URL_PROJECT.baseURL + "v2/project/role/change", payload, {
        // .post(API_URLS.baseURL + "v2/project/role/change", payload, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  editAdminProject(payload) {
    return (
      axios
        // .put(API_URLS.baseURL + "v2/project", payload, {
        .put(MICRO_API_URL_PROJECT.baseURL + "v2/project", payload, {
          headers: authHeader(),
        })
        .then((data) => {
          return Promise.resolve(data.data);
        })
        .catch((err) => Promise.reject(err))
    );
  }

  editBuildDetails(s_run_id, build_id, sprint_name) {
    let url = "";
    if (build_id) {
      url =
        MICRO_API_URL_REPORT.baseURL +
        "v1/buildDetails?s_run_id=" +
        s_run_id +
        "&build_id=" +
        build_id;
    } else {
      url =
        MICRO_API_URL_REPORT.baseURL +
        "v1/buildDetails?s_run_id=" +
        s_run_id +
        "&sprint_name=" +
        sprint_name;
    }
    return axios
      .put(url, null, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  createSuiteTestTool(payload) {
    return axios
      .post(MICRO_API_URL_TESTEXECUTION.baseURL + "v2/suite", payload, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }
  createTestcaseTestTool(payload) {
    return axios
      .post(MICRO_API_URL_TESTEXECUTION.baseURL + "v2/test", payload, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }
  createTestcaseAlab(payload) {
    return axios
      .post(MICRO_API_URL_TESTEXECUTION.baseURL + "v3/test", payload, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }
  editTestcaseTestTool(payload, id) {
    return axios
      .put(
        MICRO_API_URL_TESTEXECUTION.baseURL + "v3/test",
        { ...payload, isolatedVersionId: id },
        { headers: authHeader() }
      )
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }

  getBuildDetails(s_run_id) {
    return axios
      .get(
        MICRO_API_URL_REPORT.baseURL +
          "v1/buildDetails/json?s_run_id=" +
          s_run_id,
        {
          // .get(API_URLS.baseURL + "buildDetails/json?s_run_id=" + s_run_id, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getAvailableSuiteTestCases(id) {
    return axios
      .get(MICRO_API_URL_TESTEXECUTION.baseURL + "test?s_id=" + id, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  getEditTestCasesDetails(id) {
    return axios
      .get(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "test/jsonformat?isolatedVersionId=" +
          id,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  deleteTestcaseTestTool(tc_id, s_id) {
    return axios
      .delete(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "v2/test?isolatedVersionId=" +
          tc_id +
          "&s_id=" +
          s_id,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  unlinkFromSuite(id, sid) {
    return axios
      .post(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "v1/test/unlink?isolatedVersionId=" +
          id +
          "&s_id=" +
          sid,
        null,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  createSocket(endpoint) {
    return new SockJS(API_URLS.baseURL + endpoint, null, {
      headers: { Authorization: `Bearer ${localStorage.getItem("usertoken")}` },
    });
  }

  getsuiteStatusCount(payload) {
    return axios
      .post(
        MICRO_API_URL_TESTEXECUTION.baseURL + "v1/dashboard/count-suite",
        payload,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  getsuiteCurrent5Runs(payload) {
    return axios
      .post(
        MICRO_API_URL_TESTEXECUTION.baseURL + "v1/dashboard/recent-runs",
        payload,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  killExecution(id, pid) {
    return axios
      .post(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "v1/autokill?s_run_id=" +
          id +
          "&pid=" +
          pid,
        null,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  getsuiteexeRuns(payload) {
    return axios
      .post(
        MICRO_API_URL_TESTEXECUTION.baseURL + "v1/dashboard/v2/running-runs",
        payload,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getSuiteStats(payload) {
    return axios
      .post(
        MICRO_API_URL_TESTEXECUTION.baseURL + "v1/dashboard/run-analytics",
        payload,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getTestcaseLibrary(payload, pageNo, sort, sortColumn, rowPerPage) {
    let apiUrl =
      sort === 0 && !sortColumn
        ? MICRO_API_URL_TESTEXECUTION.baseURL +
          "test/getAllTestCases?pageNo=" +
          pageNo +
          "&elementsOnPage=" +
          rowPerPage
        : MICRO_API_URL_TESTEXECUTION.baseURL +
          "test/getAllTestCases?pageNo=" +
          pageNo +
          "&sort=" +
          sort +
          "&sortColumn=" +
          sortColumn +
          "&elementsOnPage=" +
          rowPerPage;
    return axios
      .post(apiUrl, payload, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  importTestcases(payload) {
    return axios
      .post(MICRO_API_URL_TESTEXECUTION.baseURL + "v2/test/import", payload, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  copyTestcase(payload) {
    return axios
      .post(MICRO_API_URL_TESTEXECUTION.baseURL + "v2/test/copy", payload, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getSuiteHeatmap(payload) {
    return axios
      .post(
        MICRO_API_URL_TESTEXECUTION.baseURL + "v1/dashboard/suite-analytics",
        payload,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  integrateGit(payload) {
    return axios
      .post(
        MICRO_API_URL_TESTPAD.baseURL + "gitManagement/v2/gemjar/feature/git",
        payload,
        {
          // .post(API_URLS.baseURL + "v2/gemjar/feature/git", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  updateGit(payload) {
    return axios
      .put(
        MICRO_API_URL_TESTPAD.baseURL + "gitManagement/v2/gemjar/feature/git",
        payload,
        {
          // .put(API_URLS.baseURL + "v2/gemjar/feature/git", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  deleteGit(sid) {
    return axios
      .delete(
        MICRO_API_URL_TESTPAD.baseURL +
          "gitManagement/v2/gemjar/feature/git?s_id=" +
          sid,
        {
          // .delete(API_URLS.baseURL + "v2/gemjar/feature/git?s_id=" + sid, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  GemjarlambdaExecution(payload) {
    return axios
      .post(
        MICRO_API_URL_TESTPAD.baseURL + "lambdaManagement/v2/execute",
        payload,
        {
          // .post(API_URLS.baseURL + "v2/execute", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  readFilefromUrl(url) {
    return axios
      .get(url, {
        headers: authHeader(),
        responseType: "blob",
      })
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((err) => Promise.reject(err));
  }
  createJar(s_id) {
    return axios
      .post(
        MICRO_API_URL_TESTPAD.baseURL +
          "gitManagement/v1/gemjar/jar/git?s_id=" +
          s_id,
        null,
        {
          // .post(API_URLS.baseURL + "v1/gemjar/jar/git", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  uploadData(payload, params) {
    return axios
      .post(MICRO_API_URL_S3.baseURL + "s3/v1/upload/data", payload, {
        // .post(API_URLS.baseURL + "v1/upload/data", payload, {
        headers: { ...authHeader(), "Content-Type": "text/plain" },
        params: params,
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getAllRequirements(payload) {
    return axios
      .post(
        MICRO_API_URL_TESTPAD.baseURL +
          "folderManagement/v2/requirement/folder",
        payload,
        {
          // .post(API_URLS.baseURL + "v2/requirement/folder ", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getRequirementData(req_id) {
    return axios
      .get(
        MICRO_API_URL_TESTPAD.baseURL +
          "requirementManagement/v2/requirement/json?id=" +
          req_id,
        {
          // .get(API_URLS.baseURL + "v2/requirement/json?id=" + req_id, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getManualTestSteps(id) {
    return axios
      .get(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v2/manual/testcase/steps?isolatedVersionId=" +
          id,
        // API_URLS.baseURL + "v2/manual/testcase/steps?isolatedVersionId=" + id,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getDraftedSteps(id) {
    return axios
      .get(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v2/manual/testcase/draft/steps?id=" +
          id,
        {
          // .get(API_URLS.baseURL + "v2/manual/testcase/draft/steps?id=" + id, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getEditStepData(id, step) {
    return axios
      .get(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v2/manual/testcase/steps/json?isolatedVersionId=" +
          // API_URLS.baseURL +
          // "v2/manual/testcase/steps/json?isolatedVersionId=" +
          id +
          "&stepId=" +
          step,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getManualTCToLink(id) {
    return axios
      .get(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v2/manual/testcase/same/project?id=" +
          // API_URLS.baseURL +
          // "v2/manual/testcase/same/project?id=" +
          id +
          "&type=link",
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  deleteTestStep(id, stepId) {
    return axios
      .delete(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v2/manual/testcase/delete/step?id=" +
          // API_URLS.baseURL +
          // "v2/manual/testcase/delete/step?id=" +
          id +
          "&stepId=" +
          stepId,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  saveFinalDraft(id) {
    return axios
      .put(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v2/manual/testcase/save/steps?isolatedVersionId=" +
          // API_URLS.baseURL +
          // "v2/manual/testcase/save/steps?isolatedVersionId=" +
          id,
        null,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  copyTCAsStep(mtc_id, id) {
    return axios
      .put(
        // API_URLS.baseURL +
        // "v2/manual/testcase/steps/copy/testcase?isolatedVersionId=" +
        // mtc_id +
        // "&tcIsolatedVersionId=" +
        // id,
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v2/manual/testcase/steps/copy/testcase?isolatedVersionId=" +
          mtc_id +
          "&tcIsolatedVersionId=" +
          id,
        null,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  linkTCAsStep(mtc_id, id) {
    return axios
      .put(
        // API_URLS.baseURL +
        // "v2/manual/testcase/steps/link/testcase?isolatedVersionId=" +
        // mtc_id +
        // "&tcIsolatedVersionId=" +
        // id,
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v2/manual/testcase/steps/link/testcase?isolatedVersionId=" +
          mtc_id +
          "&tcIsolatedVersionId=" +
          id,
        null,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  addTestStep(payload, id) {
    return axios
      .put(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v2/manual/testcase/update/step?id=" +
          id,
        // API_URLS.baseURL + "v2/manual/testcase/update/step?id=" + id,
        payload,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getManualData(mtc_id) {
    return axios
      .get(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v2/manual/testcase/json?id=" +
          mtc_id,
        {
          // .get(API_URLS.baseURL + "v2/manual/testcase/json?id=" + mtc_id, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  importExcel(payload) {
    return axios
      .post(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v2/import/excel/testcase",
        payload,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  excelStatus(id) {
    return axios
      .get(MICRO_API_URL_TESTEXECUTION.baseURL + "excelStatus?excelId=" + id, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  getAttachedRequirements(req_id) {
    return axios
      .get(
        MICRO_API_URL_TESTPAD.baseURL +
          "requirementManagement/v2/attached/requirement?id=" +
          req_id,
        {
          // .get(API_URLS.baseURL + "v2/attached/requirement?id=" + req_id, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getAttachedReqInTC(mtc_id) {
    return axios
      .get(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v2/manual/testcase/attached/requirements?id=" +
          // API_URLS.baseURL +
          // "v2/manual/testcase/attached/requirements?id=" +
          mtc_id,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getAttachedTC(id) {
    return axios
      .get(
        MICRO_API_URL_TESTPAD.baseURL +
          "requirementManagement/v2/attached/requirement/manual/testcase?id=" +
          id,
        // API_URLS.baseURL + "v2/attached/requirement/manual/testcase?id=" + id,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  linkedManualTC(id) {
    return axios
      .get(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v2/attached/manual/testcase?id=" +
          id,
        {
          // .get(API_URLS.baseURL + "v2/attached/manual/testcase?id=" + id, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getAllProjectsWithAccess() {
    return axios
      .get(
        MICRO_API_URL_PROJECT.baseURL + "v2/project/role/without/viewer/access",
        {
          // .get(API_URLS.baseURL + "v2/project/role/without/viewer/access", {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getSuitesToLink(id) {
    return axios
      .get(MICRO_API_URL_TESTEXECUTION.baseURL + "v2/suite/testcase?id=" + id, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  linkToSuite(payload) {
    return axios
      .post(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v2/manual/testcase/import/suite",
        payload,
        {
          // .post(API_URLS.baseURL + "v2/manual/testcase/import/suite", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  linkToSuiteAlab(payload) {
    return axios
      .post(MICRO_API_URL_TESTEXECUTION.baseURL + "v1/test/link", payload, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  getRequirementsToAttach(id) {
    return axios
      .get(
        MICRO_API_URL_TESTPAD.baseURL +
          "requirementManagement/v2/requirement/same/project?id=" +
          id,
        {
          // .get(API_URLS.baseURL + "v2/requirement/same/project?id=" + id, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getRequirementsToAttachInTC(id) {
    return axios
      .get(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v2/manual/testcase/same/project/requirements?id=" +
          // API_URLS.baseURL +
          // "v2/manual/testcase/same/project/requirements?id=" +
          id,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  getProjectTestcases(id) {
    return axios
      .get(
        MICRO_API_URL_TESTPAD.baseURL +
          "requirementManagement/v2/requirement/same/project/manual/testcase?id=" +
          // API_URLS.baseURL + "v2/requirement/same/project/manual/testcase?id=" +
          id,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  addRemoveRequirements(payload, type) {
    return axios
      .post(
        MICRO_API_URL_TESTPAD.baseURL +
          "requirementManagement/v1/update/attached/requirement?type=" +
          type,
        // API_URLS.baseURL + "update/attached/requirement?type=" + type,
        payload,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  addRemoveInManual(payload, type) {
    return axios
      .post(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v1/update/manual/testcase/attached/requirement?type=" +
          // API_URLS.baseURL +
          // "update/manual/testcase/attached/requirement?type=" +
          type,
        payload,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  addRemoveAttachedTC(payload, type) {
    return axios
      .post(
        MICRO_API_URL_TESTPAD.baseURL +
          "requirementManagement/v1/update/requirement/attached/manual/testcase?type=" +
          // "update/requirement/attached/manual/testcase?type=" +
          type,
        payload,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  createNewFolderTestpad(payload, type) {
    return axios
      .post(
        MICRO_API_URL_TESTPAD.baseURL +
          "folderManagement/v2/folder?folderType=" +
          type,
        payload,
        {
          // .post(API_URLS.baseURL + "v2/folder?folderType=" + type, payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  editFolderTestpad(payload, type) {
    return axios
      .put(
        MICRO_API_URL_TESTPAD.baseURL + "folderManagement/v2/folder",
        payload,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  deleteFolderTestpad(id) {
    return axios
      .delete(
        MICRO_API_URL_TESTPAD.baseURL + "folderManagement/v2/folder?id=" + id,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  deleteAlabTestcase(id) {
    return axios
      .delete(
        MICRO_API_URL_TESTEXECUTION.baseURL + "v3/test?isolatedVersionId=" + id,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  deleteMlabTestcase(id) {
    return axios
      .delete(
        MICRO_API_URL_TESTEXECUTION.baseURL + "v2/manual/testcase?id=" + id,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  createNewRequirement(payload) {
    return (
      axios
        // .post(API_URLS.baseURL + "v2/requirement", payload, {
        .post(
          MICRO_API_URL_TESTPAD.baseURL +
            "requirementManagement/v2/requirement",
          payload,
          {
            headers: authHeader(),
          }
        )
        .then((data) => {
          return Promise.resolve(data.data);
        })
        .catch((err) => Promise.reject(err))
    );
  }

  createNewManual(payload) {
    return axios
      .post(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v2/manual/testcase",
        payload,
        {
          // .post(API_URLS.baseURL + "v2/manual/testcase", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  editRequirement(payload) {
    return axios
      .put(
        MICRO_API_URL_TESTPAD.baseURL + "requirementManagement/v2/requirement",
        payload,
        {
          // .put(API_URLS.baseURL + "v2/requirement", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  editManual(payload) {
    return axios
      .put(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v2/manual/testcase",
        payload,
        {
          // .put(API_URLS.baseURL + "v2/manual/testcase", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  getGemjarBaseUrl = () => {
    return jarBaseUrl;
  };
  getGempypBaseUrl = () => {
    return pypBaseUrl;
  };
  getAllScheduledJobs(s_id) {
    return axios
      .get(
        MICRO_API_URL_TESTPAD.baseURL +
          "schedulerManagement/v1/jobs?s_id=" +
          s_id,
        {
          // .get(API_URLS.baseURL + "schedule/jobs?s_id=" + s_id, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  getScheduledJobData(job_id) {
    return axios
      .get(
        MICRO_API_URL_TESTPAD.baseURL +
          "schedulerManagement/v1/job?job_id=" +
          job_id,
        {
          // .get(API_URLS.baseURL + "schedule/job?job_id=" + job_id, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  editJobStatus(jobId, status) {
    return axios
      .patch(
        MICRO_API_URL_TESTPAD.baseURL +
          "schedulerManagement/v1/job/update?jobId=" +
          jobId +
          "&status=" +
          status,
        null,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }
  createScheduleJobs(payload) {
    return axios
      .post(
        MICRO_API_URL_TESTPAD.baseURL + "schedulerManagement/v1/create",
        payload,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }
  deleteScheduledJobs(job_id) {
    return axios
      .delete(
        MICRO_API_URL_TESTPAD.baseURL +
          "schedulerManagement/v1/delete?job_id=" +
          job_id,
        {
          // .delete(API_URLS.baseURL + "schedule/delete?job_id=" + job_id, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  projectAnalysis(payload, params) {
    return axios
      .post(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "v1/dashboard/v2/project-analysis",
        payload,
        {
          headers: { ...authHeader(), "Content-Type": "application/json" },
          params: params,
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getAllFolderTC(payload) {
    return axios
      .post(
        MICRO_API_URL_TESTPAD.baseURL + "folderManagement/v2/testcase/folder",
        payload,
        {
          // .post(API_URLS.baseURL + "v2/testcase/folder", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  falsePositiveUpdate(payload) {
    return axios
      .post(MICRO_API_URL_TESTEXECUTION.baseURL + "falsePositive", payload, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }

  getVarianceInfo(id) {
    return axios
      .get(MICRO_API_URL_TESTEXECUTION.baseURL + "v2/variance/" + id, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  varianceReset(id) {
    return axios
      .put(MICRO_API_URL_TESTEXECUTION.baseURL + "reset/variance/" + id, null, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  varianceUpdate(payload) {
    return axios
      .post(MICRO_API_URL_TESTEXECUTION.baseURL + "variance", payload, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }

  falsePositiveReset(payload) {
    return axios
      .put(MICRO_API_URL_TESTEXECUTION.baseURL + "falsePositive", payload, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  getManualTestcaseCount(s_id) {
    return axios
      .get(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v1/manual/testcase-count?s_id=" +
          s_id,
        {
          // .get(API_URLS.baseURL + "manual/testcase-count?s_id=" + s_id, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  getAllManualTestcase(s_id, s_run_id) {
    return axios
      .get(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v1/manual/testcases",
        {
          // .get(API_URLS.baseURL + "manual/testcases", {
          headers: authHeader(),
          params: { s_id: s_id, s_run_id: s_run_id },
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  getManualTestcaseData(tc_run_id) {
    return axios
      .get(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v1/manual/testcase/details",
        {
          // .get(API_URLS.baseURL + "manual/testcase/details", {
          headers: authHeader(),
          params: { tc_run_id: tc_run_id },
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  putManualTcs(payload) {
    return axios
      .put(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "manualTestcaseManagement/v1/manual/insertion",
        payload,
        {
          // .put(API_URLS.baseURL + "manual/insertion", payload, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  getProjectAccessAdmin() {
    return axios
      .get(MICRO_API_URL_PROJECT.baseURL + "v1/project/access/admin", {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((err) => Promise.reject(err));
  }
  getStandupDetails(standupId) {
    return axios
      .get(MICRO_API_URL_PROJECT.baseURL + "v1/standup", {
        headers: authHeader(),
        params: { id: standupId },
      })
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((err) => Promise.reject(err));
  }
  getStandupsDetails() {
    return axios
      .get(MICRO_API_URL_PROJECT.baseURL + "v1/standup", {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((err) => Promise.reject(err));
  }

  postStandupData(payload) {
    return axios
      .post(MICRO_API_URL_PROJECT.baseURL + "v1/standup", payload, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }
  updateStandupData(payload) {
    return axios
      .put(MICRO_API_URL_PROJECT.baseURL + "v1/standup", payload, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }

  getStandupValidate(standupId) {
    return axios
      .get(MICRO_API_URL_PROJECT.baseURL + "v1/standup/validate/live", {
        headers: authHeader(),
        params: { id: standupId },
      })
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((err) => Promise.reject(err));
  }
  getFillingStatus(standupId) {
    return axios
      .get(MICRO_API_URL_PROJECT.baseURL + "v1/filling/status", {
        headers: authHeader(),
        params: { id: standupId },
      })
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((err) => Promise.reject(err));
  }
  markOnLeave(payload) {
    return axios
      .post(MICRO_API_URL_PROJECT.baseURL + "v1/standup/leave", payload, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }
  postStandupStatus(payload, standupId) {
    return axios
      .post(MICRO_API_URL_PROJECT.baseURL + "v1/status", payload, {
        headers: authHeader(),
        params: { id: standupId },
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }
  updateStandupStatus(payload, standupId) {
    return axios
      .put(MICRO_API_URL_PROJECT.baseURL + "v1/status", payload, {
        headers: authHeader(),
        params: { id: standupId },
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }
  deleteStandup(standupId) {
    return axios
      .delete(MICRO_API_URL_PROJECT.baseURL + "v1/standup", {
        headers: authHeader(),
        params: { id: standupId },
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }
  verifySignUpOtp(username, otp) {
    return axios.post(
      MICRO_API_URL_USER.baseURL + "userManagement/v1/account/verify",
      {
        username: username,
        otp: otp,
      }
    );
  }
  sendSignUpOtp(username) {
    return axios.post(MICRO_API_URL_USER.baseURL + "v1/otp", {
      username: username,
      type: "verify",
    });
  }
  getNotifications(username) {
    return axios
      .get(MICRO_API_URL_NOTIFICATION.baseURL + "v1/notification/get", {
        headers: authHeader(),
        params: { username: username, app: "jewel" },
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }
  clearAllNotifications(username) {
    return axios
      .post(MICRO_API_URL_NOTIFICATION.baseURL + "v1/notification/read", null, {
        headers: authHeader(),
        params: { username: username, app: "jewel" },
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }

  clearNotification(username, notificationId) {
    return axios
      .post(MICRO_API_URL_NOTIFICATION.baseURL + "v1/notification/read", null, {
        headers: authHeader(),
        params: {
          username: username,
          notificationId: notificationId,
          app: "jewel",
        },
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }
  sendReminder(payload, standupId) {
    return axios
      .post(MICRO_API_URL_PROJECT.baseURL + "v1/worklog/reminder", payload, {
        headers: authHeader(),
        params: { id: standupId },
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }
  changeEmail(payload) {
    return axios.post(
      MICRO_API_URL_USER.baseURL + "userManagement/v2/user",
      payload,
      {
        params: {
          type: "update",
        },
      }
    );
  }
  sendMonthlyMail(standupId) {
    return axios
      .get(MICRO_API_URL_PROJECT.baseURL + "v1/email/monthly?id=" + standupId, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }

  getCsv(url) {
    return axios
      .get(url, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  }
}
export default new HttpService();
